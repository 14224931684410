import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Disqus } from 'gatsby-plugin-disqus'

type BlogDisqusProps = {
  title: string
  path: string
}

export const BlogDisqus: React.FC<BlogDisqusProps> = ({ title, path }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          prodSiteUrl
        }
      }
    }
  `)
  const { prodSiteUrl } = data.site.siteMetadata
  return (
    <>
      <Disqus
        config={{
          url: prodSiteUrl + path,
          identifier: path,
          title,
        }}
      />
    </>
  )
}
