import React from 'react'
import { Link } from 'gatsby'

type BlogSidebarProps = {
  advanced?: boolean
  hackerNewsURL?: string
  redditURL?: string
  date?: string
}

export const BlogSidebar: React.FC<BlogSidebarProps> = ({
  advanced = false,
  hackerNewsURL,
  redditURL,
  date,
}) => {
  return (
    <div className='sidebar-layout__sidebar sidebar-layout__sidebar--narrow blog-post-sidebar'>
      <div className='sidebar-bordered-section'>
        {advanced && (
          <div className='blog-post-sidebar__advanced'>
            {date && (
              <div className='blog-post-sidebar__date'>
                {'Posted by '}
                <a href='https://github.com/arshaw' style={{ fontWeight: 800 }}>
                  arshaw
                </a>
                {` on ${new Date(date).toDateString()}`}
              </div>
            )}

            <ul className='blog-post-sidebar__list'>
              {hackerNewsURL && (
                <li>
                  <a href={hackerNewsURL} target='_blank' rel='noreferrer'>
                    View on Hacker News
                  </a>
                </li>
              )}
              {redditURL && (
                <li>
                  <a href={redditURL} target='_blank' rel='noreferrer'>
                    View on Reddit
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}

        <h2 className='blog-post-sidebar__title'>Featured Posts</h2>
        <ul className='blog-post-sidebar__list'>
          <li>
            <Link to='/blog/2022/11/v6-beta-released'>V6 Beta Released</Link>
          </li>
          <li>
            <Link to='/blog/2021/02/10percent-profits-to-effective-charities'>
              FullCalendar LLC Pledges to Donate 10% of Profits to EA Charities
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
