import React from 'react'
import { graphql, PageProps, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { BlogSidebar } from '@components/blogSidebar'
import { BlogDisqus } from '@components/blogDisqus'
import BaseLayout from './base'

// This file is used in gatsby-node to generate blog post pages

type DataProps = {
  file: {
    childMdx: {
      body: string
      frontmatter: {
        date: string
        title: string
        advancedSidebar?: boolean
        hackerNewsURL?: string
        redditURL?: string
      }
    }
  }
}

type ContextProps = {
  id: string
  previousPost: { title: string; slug: string }
  nextPost: { title: string; slug: string }
}

const BlogPost: React.FC<PageProps<DataProps, ContextProps>> = ({ data, pageContext, path }) => {
  const { body, frontmatter } = data.file.childMdx
  return (
    <BaseLayout title={`${frontmatter.title}`} pageIsResponsive>
      <section className='page-content'>
        <div className='page-content__container container'>
          <h2 className='page-content__title'>
            <Link to='/blog'>Blog</Link>
          </h2>
          <div className='sidebar-layout'>
            <div className='sidebar-layout__main'>
              <div className='blog-post'>
                <h1 className='blog-post__title'>{frontmatter.title}</h1>

                <div className='blog-post__content text-content'>
                  <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
                </div>
                <div className='blog-post__meta'>
                  {`Posted ${new Date(frontmatter.date).toDateString()}`}
                </div>
              </div>
              <div className='blog-pager'>
                {pageContext.previousPost.slug && (
                  <Link
                    to={`/blog/${pageContext.previousPost.slug}`}
                    className='blog-pager__prev'
                    rel='prev'
                  >
                    {pageContext.previousPost.title}
                  </Link>
                )}
                {pageContext.nextPost.slug && (
                  <Link
                    to={`/blog/${pageContext.nextPost.slug}`}
                    className='blog-pager__next'
                    rel='next'
                  >
                    {pageContext.nextPost.title}
                  </Link>
                )}
              </div>
              <div className='comment-warning'>
                {`Psst... there are separate pages for `}
                <Link to='/requesting-features'>feature requests</Link>
                {`, `}
                <Link to='/reporting-bugs'>bug reports</Link>
                {`, and `}
                <Link to='/getting-help'>getting help</Link>.
              </div>

              <BlogDisqus path={path} title={frontmatter.title} />
            </div>
            <BlogSidebar
              advanced={frontmatter.advancedSidebar}
              date={frontmatter.date}
              hackerNewsURL={frontmatter.hackerNewsURL}
              redditURL={frontmatter.redditURL}
            />
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    file(id: { eq: $id }) {
      childMdx {
        body
        frontmatter {
          date
          title
          advancedSidebar
          hackerNewsURL
          redditURL
        }
      }
    }
  }
`

export default BlogPost
